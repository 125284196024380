import { render, staticRenderFns } from "./CoolingChamberDetail.vue?vue&type=template&id=3e592220&scoped=true"
import script from "./CoolingChamberDetail.vue?vue&type=script&lang=js"
export * from "./CoolingChamberDetail.vue?vue&type=script&lang=js"
import style0 from "./CoolingChamberDetail.vue?vue&type=style&index=0&id=3e592220&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e592220",
  null
  
)

export default component.exports